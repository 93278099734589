import React from 'react'
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail'
import ContactForm1 from '../components/ContactForm1'
import ContactForm2 from './ContactForm2';
import ContactForm3 from './ContactForm3';


const Section3 = () => {
    return (
        <section id='contact'>
            <div className='container mx-auto bg-neutral-700 mt-12'>
                <h1 className='text-white text-center font-Rampart text-2xl pt-6'>Kontaktieren Sie uns</h1>
                <p className='text-center text-white mx-12 mt-6 '>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Distinctio doloremque voluptates provident velit adipisci.</p>

                <div className="flex flex-col md:flex-row mx-auto">
                    <div className="bg-neutral-700  basis-1/4  hidden md:block">
                        <div className="bg-white w-40 h-40 rounded-full flex justify-center items-center mx-auto mt-16">
                            <HomeIcon fontSize="large" />
                        </div>
                        <div className="bg-white w-40 h-40 rounded-full flex justify-center items-center mx-auto mt-24">
                            <PhoneIcon fontSize="large" />
                        </div>
                        <div className="bg-white w-40 h-40 rounded-full flex justify-center items-center mx-auto mt-24">
                            <MailIcon fontSize="large" />
                        </div>


                    </div>
                    <div className="basis-1/4 bg-neutral-700">
                        <div className='mt-20'>
                            <p className='text-center text-4xl font-bold text-violet-600'>Adresse</p>
                            <p className='text-center text-2xl font-medium text-slate-200'>im Heuberg 1</p>
                            <p className='text-center text-2xl font-medium text-slate-200'>8127 Forch</p>
                        </div>
                        <div className='mt-36'>
                            <p className='text-center text-4xl font-bold text-violet-600'>Telefon</p>
                            <p className='text-center text-2xl font-medium text-slate-200'>044 980 08 06</p>
                            <p className='text-center text-2xl font-medium text-slate-200'>8127 Forch</p>
                        </div>
                        <div className='mt-36'>
                            <p className='text-center text-4xl font-bold text-violet-600'>EMail</p>
                            <p className='text-center text-2xl font-medium text-slate-200'>info@brennerei-kunz.ch</p>

                        </div>
                    </div>
                    <div className="bg-neutral-700 basis-1/2">
                        <ContactForm3 />
                    </div>
                </div>

            </div>

        </section>
    )
}

export default Section3