import React from 'react'
import { useRef } from 'react'


const ContactForm3 = () => {

    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const messageRef = useRef(null)

    const handleSubmit = (event) => {
        event.preventDefault()

        const data = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            message: messageRef.current.value
        }
        alert("tadaaa!: \n" + JSON.stringify(data) + "Your data 😎")
    }



    return (
        <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md mt-20  mx-auto">
            <form onSubmit={handleSubmit}>
                <div className="form-group mb-6">
                    <input
                        type="text"
                        className="form-control block
                            w-full
                             px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                        bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                         m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="exampleInput7"
                        placeholder="Name"
                        ref={nameRef}>
                    </input>
                </div>

                <div className="form-group mb-6">
                    <input type="email" className="form-control block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="exampleInput8"
                        placeholder="Email address"
                        ref={emailRef}>
                    </input>
                </div>

                <div className="form-group mb-6">
                    <textarea
                        className="form-control block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                             rounded
                             transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="exampleFormControlTextarea13"
                        rows="3"
                        placeholder="Message"
                        ref={messageRef}
                    ></textarea>

                    <button type="submit" className="
                    mt-2
                        w-full
                        px-6
                        py-2.5
                        bg-blue-600
                        text-white
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out">Send</button>

                </div>




            </form>



        </div>
    )
}

export default ContactForm3