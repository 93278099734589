import { Box } from '@mui/material'
import React from 'react'









const Section1 = () => {
    return (
        <section id='home'>
            <div className=" px-8 bg-black">
                <img src={require('../content/images/bk01.jpg')}
                >
                </img>

                <h1 className='bg-black text-white text-center mt-12 font-Rampart text-4xl'>Brennerei Kunz</h1>
                <p className='bg-black text-white text-left ml-4 mt-12 pb-8'>Lorem
                    ipsum dolor sit amet consectetur, adipisicing elit.
                    Rem esse distinctio dolor laudantium sapiente ut vero
                    voluptates culpa quam iure eius dolores neque tempora numquam,
                    illum nam id enim perferendis odio illo dolorem aspernatur quae. Amet odio error possimus sint. Assumenda, blanditiis laborum nesciunt ipsam amet magnam, voluptatem quidem ad cum consequuntur reiciendis repellat facilis quis perferendis tempora repudiandae magni dolorum necessitatibus. Saepe iusto quisquam, dolores rerum esse dolorem laborum corporis laudantium aperiam porro. Hic dolore sapiente pariatur debitis nihil?</p>

            </div>

        </section >
    )
}

export default Section1


