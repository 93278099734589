import React from 'react'

const pages = [{ display: "Home", href: '/' },
{ display: "Produkte", href: '#products' },
{ display: "Kontakt", href: '#contact' }
];



const Test = () => {
    return (
        <div>
            {pages.map((page) => (
                //console.log(Object.values(page)[0])
                Object.values(page)[0] = Object.values(page)[0]

            )
            )}
        </div>
    )
}

export default Test