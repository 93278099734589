import logo from './logo.svg';
import ResponsiveAppBar from './components/ResNavbar'
import SectionHome from './components/Section1'
import SectionProducts from './components/Section2'
import SectionContact from './components/Section3'
import Test from './components/Test'


function App() {
  return (
    <div className="App">
      <ResponsiveAppBar />
      <SectionHome />
      <SectionProducts />
      <SectionContact />
      <Test />
    </div>
  );
}

export default App;


//Web Sides
//https://dev.to/byteslash/how-to-use-google-fonts-in-tailwindcss-5050  install a font
