import React from 'react'
import ProductItem from './ProductItem'
import Produkte from '../data/data.json'
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";


const Section2 = () => {
    return (
        <section id='products'>
            <div className="container mx-auto mt-18 ">

                <h1 className=' text-center mt-20 font-Rampart text-2xl mb-6'>Unsere Produkte</h1>
                <div className='container grid grid-cols-2 md:grid-cols-3  gap-4 '>

                    {Produkte.map((produkt) => (
                        <>
                            <ProductItem key={produkt.id}
                                title={produkt.title}
                                text={produkt.text}
                                image={produkt.image}>
                            </ProductItem>
                        </>
                    ))}


                </div>


            </div>
        </section >

    )
}

export default Section2