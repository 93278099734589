import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/material/Icon';    //ByRRR
import MenuIcon from '@material-ui/icons/Menu';  //Replace
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import { green } from '@mui/material/colors';
import { SportsRugby } from '@material-ui/icons';

//import { scroller } from "react-scroll";


const mypages = [{ display: "Home", href: 'home' },
{ display: "Produkte", href: 'products' },
{ display: "Kontakt", href: 'contact' }
];



const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null | HTMLElement > (null));
    const [anchorElUser, setAnchorElUser] = React.useState(null | HTMLElement > (null));

    // Disable in Workspace (Preferences->Settings): javascript.validate

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };



    // onClick with Parameter
    const handleMenuClick = target => () => {


        const element = document.getElementById(target);
        const offset = 70;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        handleCloseNavMenu();
    }


    // const scrollToSection = () => {
    //     scroller.scrollTo("#products", {
    //         duration: 800,
    //         delay: 0,
    //         smooth: "easeInOutQuart",
    //     });
    // };



    let Location = "https://www.google.ch/maps/place/Brennerei+Kunz/@47.3156787,8.6657908,17z/data=!3m1!4b1!4m5!3m4!1s0x479aa5aafd70df0b:0xd472b8bf7b4d3062!8m2!3d47.3156688!4d8.6679602?hl=de&authuser=0"


    return (
        <AppBar position="sticky" sx={{ background: 'gray' }} >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        BK
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip title="Wähle ein Command">
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {/* Collapse Menu */}
                            {mypages.map((page) => (
                                <MenuItem
                                    key={Object.values(page)[0]}
                                    onClick={handleMenuClick(Object.values(page)[1])}
                                    component="a"
                                //href={Object.values(page)[1]}
                                >
                                    <Typography textAlign="center">{Object.values(page)[0]}</Typography>
                                </MenuItem>

                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        BG
                    </Typography>
                    {/* Normal Menu */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {mypages.map((page) => (
                            <Button
                                key={Object.values(page)[0]}
                                onClick={handleMenuClick(Object.values(page)[1])}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {Object.values(page)[0]}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Anrufen">
                            <IconButton href="tel:+41449800806" target="_blank">
                                <PhoneIcon
                                    style={{ color: 'white' }}

                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Zeige Google Map">
                            <IconButton href={Location} target="_blank">
                                <LocationOnIcon
                                    style={{ color: 'white' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default ResponsiveAppBar;
